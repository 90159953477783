import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import * as XLSX from 'xlsx';
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { sendRequest } from "config/apiFunctions";
import { Autocomplete, InputAdornment, PaginationItem } from "@mui/material";
import "./style.css"
import styled from "@emotion/styled";


export default function Data() {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [filterValue, setFilterValue] = useState("USER");
  const itemsPerPage = 10;
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0]); //startdate initialization must be 1month ago date
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

  const [loading, setLoading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuItem, setMenuItem] = useState(null);

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [selectedVillageId, setSelectedVillageId] = useState(null);

  // Add these fetch functions
  const fetchRegions = async () => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "get",
        url: "/general/search/regions",
        token
      });
      if (res.success) {
        setRegions(res.data);
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  const fetchDistricts = async (regionId) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "get",
        url: `/general/districts/${regionId}`,
        token
      });
      if (res.success) {
        setDistricts(res.data);
        if (res.data.length > 0 && res.data[0].villages) {
          setVillages(res.data[0].villages);
        }
      }
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };


  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
  };

  const openMenu = (event, item) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }));

  const handleExportExcel = async () => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;

    // Build location params based on current selection
    const locationParams = {};
    if (selectedVillageId) {
      locationParams.villageId = selectedVillageId;
    } else if (selectedDistrictId) {
      locationParams.districtId = selectedDistrictId;
    } else if (selectedRegionId) {
      locationParams.regionId = selectedRegionId;
    }

    try {
      const response = await sendRequest({
        method: "get",
        url: "/admin/users",
        token,
        params: {
          startDate: startDate,
          endDate: endDate,
          roles: filterValue,
          ...locationParams,

          "sortBy": "createdAt"
        }
      });

      // Ma'lumotlarni Excel formatiga o'zgartirish
      const excelData = response.data.data.map(item => ({
        'Viloyat': item.address?.region?.name || '',
        'Tuman': item.address?.district?.name || '',
        'Mahalla': item.address?.village?.name || '',
        'Telefon': item.phoneNumber || '',
        'To\'lovlar soni': item.subscriptions?.payments?.length || 0,
        'Ro\'yxatdan o\'tgan sana': new Date(item.createdAt).toLocaleString('uz-UZ'),
        'Ism': item.firstName || '',
        'Familiya': item.lastName || ''
      }));

      // Excel faylni yaratish
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

      // Ustun kengliklarini sozlash
      const maxWidth = excelData.reduce((acc, row) => {
        Object.keys(row).forEach(key => {
          const length = (row[key] || '').toString().length;
          acc[key] = Math.max(acc[key] || 0, length);
        });
        return acc;
      }, {});

      worksheet['!cols'] = Object.keys(excelData[0]).map(key => ({
        wch: Math.max(maxWidth[key], key.length)
      }));

      // Faylni yuklash
      XLSX.writeFile(workbook, 'users.xlsx');

      showAlert("Excel fayl muvaffaqiyatli yuklandi", 'success');
    } catch (error) {
      console.error("Export error:", error);
      showAlert("Excel faylni yuklab olishda xatolik yuz berdi", 'error');
    }
  };
  const fetchUsersWithParams = async (params) => {
    setLoading(true);
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;

    try {
      const res = await sendRequest({
        method: "get",
        url: "/admin/users",
        token,
        params: {
          ...params,
          sortBy: "createdAt"
        }
      });

      if (res.success) {
        setData(res.data.data);
        setTotal(res.data.meta.total);
        let totalPages = Math.ceil(res.data.meta.total / 10);
        setTotalPages(totalPages);
        setPage(res.data.page);

        const newRows = res.data.data.map((item) => ({
          client: <Author name={item.fullName ?? "-"} phone={item.phoneNumber ?? "-"} />,
          buyurtmalar: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.acceptedJobPosts?.length ?? "0"}
            </MDTypography>
          ),
          status: (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status} color="success" variant="gradient" size="sm" />
            </MDBox>
          ),
          address: <Job description={(item.address?.region?.name ? item.address?.region?.name : "") + ", " + (item.address?.district?.name ? item.address?.district?.name + ", " : "") + (item.address?.addressLine ? item.address?.addressLine : "") ?? "Mavjud emas"} />,
        }));
        setRows(newRows);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      showAlert("Ma'lumotlarni yuklashda xatolik yuz berdi", 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = useCallback(async (pageNumber = 1, startDate = `${new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0]}`, endDate = `${new Date().toISOString().split('T')[0]}`, roles = "USER") => {
    setLoading(true);
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;

    // Basic params
    const params = {
      page: pageNumber,
      limit: itemsPerPage,
      startDate: startDate,
      endDate: endDate,
      roles: roles,
      "sortBy": "createdAt"
    };

    // Add only the last selected location parameter
    if (selectedVillageId) {
      params.villageId = selectedVillageId;
    } else if (selectedDistrictId) {
      params.districtId = selectedDistrictId;
    } else if (selectedRegionId) {
      params.regionId = selectedRegionId;
    }

    try {
      const res = await sendRequest({
        method: "get",
        url: "/admin/users",
        token,
        params
      });
      if (res.success) {
        console.log("API response:", res.data);
        setData(res.data.data);
        setTotal(res.data.meta.total);
        let totalPages = Math.ceil(res.data.meta.total / 10);
        setTotalPages(totalPages);
        setPage(res.data.page);

        const newRows = res.data.data.map((item) => ({
          client: <Author name={item.fullName ?? "-"} phone={item.phoneNumber ?? "-"} />,
          buyurtmalar: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.acceptedJobPosts?.length ?? "0"}
            </MDTypography>
          ),
          status: (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status} color="success" variant="gradient" size="sm" />
            </MDBox>
          ),
          address: <Job description={(item.address?.region?.name ? item.address?.region?.name : "") + ", " + (item.address?.district?.name ? item.address?.district?.name + ", " : "") + (item.address?.addressLine ? item.address?.addressLine : "") ?? "Mavjud emas"} />,
        }));
        setRows(newRows);
        setData(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      showAlert("Ma'lumotlarni yuklashda xatolik yuz berdi", 'error');
    } finally {
      setLoading(false);
    }
  }, [itemsPerPage, showAlert, openMenu, selectedRegionId, selectedDistrictId, selectedVillageId]);// Dependency array-ga state'larni qo'shdik
  useEffect(() => {
    fetchUsers();
    fetchRegions();
  }, []);



  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertInfo({ ...alertInfo, open: false });
  };
  useEffect(() => {
    if (data.length) {
      const filteredData = data.filter(item => {
        const fullName = (item.fullName ?? "").toLowerCase();
        const addressId = (item.addressId ?? "").toLowerCase();
        const filter = (filterValue ?? "").toLowerCase();
        return fullName.includes(filter) || addressId.includes(filter);
      });

      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = filteredData.slice(startIndex, endIndex);

      const newRows = paginatedData.map((item) => ({
        client: <Author name={item.fullName ?? "-"} phone={item.phoneNumber ?? "-"} />,
        buyurtmalar: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.acceptedJobPosts?.length ?? "0"}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status} color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        address: <Job description={(item.address?.region?.name ? item.address?.region?.name : "") + ", " + (item.address?.district?.name ? item.address?.district?.name + ", " : "") + (item.address?.addressLine ? item.address?.addressLine : "") ?? "Mavjud emas"} />,

      }));
      setTotal(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [data, page, filterValue]);

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchUsers(value, startDate, endDate, filterValue);
  };
  const Author = ({ name, phone }) => (
    <MDBox display="flex" flexDirection="column">
      <MDTypography variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption" color="secondary">
        {phone}
      </MDTypography>
    </MDBox>
  );
  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );
  return {
    FilterInput: (
      <Box sx={{ mb: 3 }}>
        {/* User Type Row */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 2,
            mb: 2
          }}
        >

          <Button
            variant="contained"
            color="success"
            onClick={handleExportExcel}
            sx={{
              height: "56px",
              width: "100%"
            }}
          >
            Excel ga yuklash
          </Button>
        </Box>

        {/* Address Fields Row */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 2,
            mb: 2,
            '& .MuiFormControl-root': {
              margin: 0,
              width: '100%'
            },
            '& .MuiAutocomplete-root': {
              width: '100%'
            }
          }}
        >
          <FormControl>
            <Autocomplete
              options={regions}
              getOptionLabel={(option) => option.name}
              value={regions.find(region => region.id === selectedRegionId) || null}
              onChange={(event, newValue) => {
                setSelectedRegionId(newValue ? newValue.id : null);
                setSelectedDistrictId(null);
                setSelectedVillageId(null);
                setDistricts([]);
                setVillages([]);
                const params = {
                  page: 1,
                  limit: itemsPerPage,
                  startDate,
                  endDate,
                  roles: filterValue,
                  regionId: newValue?.id
                };
                fetchUsersWithParams(params);
                if (newValue) {
                  fetchDistricts(newValue.id);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Viloyat" variant="outlined" />
              )}
            />
          </FormControl>

          <FormControl>
            <Autocomplete
              options={districts}
              getOptionLabel={(option) => option.name}
              value={districts.find(district => district.id === selectedDistrictId) || null}
              onChange={(event, newValue) => {
                setSelectedDistrictId(newValue ? newValue.id : null);
                setSelectedVillageId(null);
                const params = {
                  page: 1,
                  limit: itemsPerPage,
                  startDate,
                  endDate,
                  roles: filterValue,
                  districtId: newValue?.id
                };
                fetchUsersWithParams(params);
                if (newValue && newValue.villages) {
                  setVillages(newValue.villages);
                } else {
                  setVillages([]);
                }
              }}
              disabled={!selectedRegionId}
              renderInput={(params) => (
                <TextField {...params} label="Tuman" variant="outlined" />
              )}
            />
          </FormControl>

          <FormControl>
            <Autocomplete
              options={villages}
              getOptionLabel={(option) => option.name}
              value={villages.find(village => village.id === selectedVillageId) || null}
              onChange={(event, newValue) => {
                setSelectedVillageId(newValue ? newValue.id : null);
                const params = {
                  page: 1,
                  limit: itemsPerPage,
                  startDate,
                  endDate,
                  roles: filterValue,
                  villageId: newValue?.id
                };
                fetchUsersWithParams(params);
              }}
              disabled={!selectedDistrictId}
              renderInput={(params) => (
                <TextField {...params} label="Mahalla" variant="outlined" />
              )}
            />
          </FormControl>
        </Box>

        {/* Date Fields and Export Button Row */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 2,
            '& .MuiTextField-root': {
              margin: 0,
              width: '100%'
            }
          }}
        >
          <TextField
            type="date"
            label="Boshlanish sanasi"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              fetchUsers(page, e.target.value, endDate, filterValue);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            label="Tugash sanasi"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
              fetchUsers(page, startDate, e.target.value, filterValue);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <FormControl
            margin="normal"
            sx={{
              minHeight: "80px",
              width: "100%",
              margin: 0
            }}
          >
            <InputLabel>Foydalanuvchi turi</InputLabel>
            <Select
              value={filterValue}
              onChange={(event) => {
                setFilterValue(event.target.value);
                fetchUsers(1, startDate, endDate, event.target.value);
              }}
              label="Foydalanuvchi turi"
              sx={{ height: "44px" }}
            >
              <MenuItem value="">Barchasi</MenuItem>
              <MenuItem value="JOB_POSTER">Ishchi Bor</MenuItem>
              <MenuItem value="USER">Foydalanuvchi</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    ),
    AlertComponent: (
      <Snackbar open={alertInfo.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertInfo.severity} sx={{ width: '100%' }}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    ),
    columns: [
      { Header: "Name/Phone", accessor: "client", width: "30%", align: "left" },
      { Header: "Buyurtmalar Soni", accessor: "buyurtmalar", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Address", accessor: "address", align: "center" },
    ],
    rows: rows,
    Pagination: (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          renderItem={(item) => (
            <CustomPaginationItem
              {...item}
              sx={{
                fontWeight: item.selected ? 'bold' : 'normal',
              }}
            />
          )}
        />
      </Box>
    ),
  };
}