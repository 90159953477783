import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { sendRequest } from 'config/apiFunctions';

function Dashboard() {
  const [stats, setStats] = useState({
    userCount: 0,
    activeJobsCount: 0,
    completedJobsCount: 0,
    totalRevenue: 0,
    jobPosterCount: 0,
    activeSubscribedUsersCount: 0,
    inactiveTrialUsersCount: 0,
    activeTrialUsersCount: 0,
    jobPosterJobsCount: 0,
  });


  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;

    try {
      const res = await sendRequest({ method: "get", url: "/general/stats", token });
      if (res.success) {
        setStats(res.data);
      }
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Foydalanuvchilar Soni"
                count={stats.userCount.toString()}
                percentage={{
                  color: "success",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Active Ishlar"
                count={stats.activeJobsCount.toString()}
                percentage={{
                  color: "success",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Tugatilgan Ishlar"
                count={stats.completedJobsCount.toString()}
                percentage={{
                  color: "success",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Umumiy Kirim"
                count={`uzs ${stats.totalRevenue.toLocaleString()}`}
                percentage={{
                  color: "success",
                  amount: "",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={4}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="money"
                title="Active Sotib olganlar 
                soni"
                count={stats.activeSubscribedUsersCount.toString()}
                percentage={{
                  color: "success",
                }}
              />
            </MDBox>
          </Grid>


          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="manageAccounts"
                title="Vaqtincha Bepul ishlatayotganlar soni"
                count={stats.activeTrialUsersCount.toString()}
                percentage={{
                  color: "success",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="warning"
                title="Pul to'lamaganlar soni"
                count={stats.inactiveTrialUsersCount.toString()}
                percentage={{
                  color: "success",
                }}
              />
            </MDBox>
          </Grid>

        </Grid>
        <Grid container spacing={3} mt={4}>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="work"
                title="Ishchi bordan qoyilgan e'lonlar soni"
                count={stats.jobPosterJobsCount.toLocaleString()}
                percentage={{
                  color: "success",
                  amount: "",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="group"
                title="Ishchi bor Foydalanuvchilar Soni"
                count={stats.jobPosterCount.toString()}
                percentage={{
                  color: "success",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;