
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables5/data/authorsTableData";

function Tables5() {
  const { columns, rows, FilterInput, Pagination, AlertComponent } = authorsTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {AddButton} */}
      {FilterInput}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Foydalanuvchilar Ro'yhati
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  rows.length === 0 ? (
                    <MDBox textAlign="center" p={3}>
                      <MDTypography variant="h6" color="text">
                        Ma'lumot mavjud emas
                      </MDTypography>
                    </MDBox>) : <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                }

              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      {Pagination}
      {AlertComponent}
    </DashboardLayout>

  );
}

export default Tables5;
