import axios from "axios"

const checkAuth = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  const PUBLIC_ROUTES = ["authentication/sign-in"]

  const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r))

  if (!user && !isPublicPage) {
    window.location.href = '/authentication/sign-in'
    return;
  } else {
    axios.interceptors.request.use(function (config) {
      document.body.classList.add('loading-indicator');
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      document.body.classList.remove('loading-indicator');
      return response;
    }, function (error) {
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    });
    return user
  }
}

export default checkAuth