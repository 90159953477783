import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { sendRequest } from "config/apiFunctions";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Data() {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const itemsPerPage = 10;
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuItem, setMenuItem] = useState(null);
  const [imageName, setImageName] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);


  const { control: addControl, handleSubmit: handleAddSubmit, reset: resetAddForm, setValue } = useForm({
    defaultValues: {
      image: null
    }
  });
  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
  };

  const openMenu = (event, item) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuItem(null);
  };


  const fetchUsers = async (params) => {
    console.log("fetchUsers");
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({ method: "get", url: "/admin/ads", token, params });
      if (res.success) {
        console.log(res);
        setData(res.data);
        setTotal(res.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const createRows = useCallback((data) => {
    return data.map((item) => ({
      client: <Author name={item?.postedBy?.fullName ?? "-"} phone={item?.postedBy?.phoneNumber ?? "-"} />,
      address: <Job url={item?.url} />,
      action: (
        <div>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={(event) => openMenu(event, item)}
          >
            more_vert
          </Icon>
        </div>
      ),
    }));
  }, [openMenu]);
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertInfo({ ...alertInfo, open: false });
  };


  const handleOpenDeleteModal = useCallback((item) => {
    setSelectedItem(item);
    setDeleteModalOpen(true);
    closeMenu();
  }, [closeMenu]);

  useEffect(() => {
    if (data.length) {
      const filteredData = data.filter(item => {
        const fullName = (item?.postedBy?.fullName ?? "").toLowerCase();
        const filter = (filterValue ?? "").toLowerCase();
        return fullName.includes(filter);
      });

      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = filteredData.slice(startIndex, endIndex);

      const newRows = paginatedData.map((item) => ({
        client: <Author name={item?.postedBy?.fullName ?? "-"} phone={item?.postedBy?.phoneNumber ?? "-"} />,
        address: <Job url={item?.url} />,
        action: (
          <div>
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
              onClick={(event) => openMenu(event, item)}
            >
              more_vert
            </Icon>
          </div>
        ),
      }));
      setRows(newRows);
      setTotal(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [data, page, filterValue]);

  const renderMenu = useCallback(() => (
    <Menu
      anchorEl={menuAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menuAnchorEl)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => { handleOpenDeleteModal(menuItem); closeMenu(); }}>O'chirish</MenuItem>
    </Menu>
  ), [menuAnchorEl, menuItem, handleOpenDeleteModal]);

  const handleOpenAddModal = useCallback(() => {
    resetAddForm();
    setImageName("");
    setAddModalOpen(true);
  }, [resetAddForm]);

  const handleDelete = useCallback(async () => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "delete",
        url: `/admin/ads/${selectedItem.id}`,
        token
      });
      if (res.success) {
        // const updatedData = await fetchUsers(page, filterValue);
        const updatedData = data.filter(item => item.id !== selectedItem.id);
        setData(updatedData);
        setRows(createRows(updatedData));
        setDeleteModalOpen(false);
        showAlert("Reklama muvaffaqiyatli o'chirildi", 'success');
      } else {
        console.error("Delete failed:", res.error);
        showAlert("Reklamani o'chirishda xatolik yuz berdi", 'error');
      }
    } catch (error) {
      console.error("Delete error:", error);
      showAlert("Reklamani o'chirishda xatolik yuz berdi", 'error');
    }
  }, [selectedItem, page, filterValue, fetchUsers, createRows, showAlert]);



  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const DeleteModal = useCallback(() => (
    <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          O'chirishni tasdiqlang
        </MDTypography>
        <MDTypography sx={{ mt: 2 }}>
          Haqiqatan ham bu reklamani o'chirib tashlamoqchimisiz?
        </MDTypography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setDeleteModalOpen(false)}>Bekor qilish</Button>
          <Button onClick={handleDelete} color="error">O'chirish</Button>
        </Box>
      </Box>
    </Modal>
  ), [deleteModalOpen, handleDelete]);
  const onAddSubmit = async (formData) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    if (!token) {
      console.error("Token topilmadi");
      return;
    }
    const data = new FormData();
    if (formData.image) {
      data.append('file', formData.image);
    }

    try {
      const response = await fetch('https://api.ishbor.andasoft.uz/admin/ads', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: data
      });

      if (!response.ok) {
        throw new Error('Serverdan xato javob keldi');
      }
      const result = await response.json();
      console.log(result);
      if (result) {
        // const updatedData = await fetchUsers(page, filterValue);
        const updatedData = [result.data, ...data];
        setData(updatedData);
        // setRows(createRows(updatedData));
        setAddModalOpen(false);
        resetAddForm();
        setImageName("");
        setPreviewUrl(null);
        showAlert("Yangi reklama muvaffaqiyatli qo'shildi", 'success');
      } else {
        console.error("Qo'shish muvaffaqiyatsiz bo'ldi:", result.error);
        showAlert("Yangi reklama qo'shishda xatolik yuz berdi", 'error');
      }
    } catch (error) {
      console.error("Xatolik yuz berdi:", error);
      showAlert("Yangi reklama qo'shishda xatolik yuz berdi", 'error');
    }
  };

  const handleImageChange = (e, onChange) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      onChange(file);
      setImageName(file.name);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };
  const handleImageDelete = () => {
    setValue('image', null);
    setImageName("");
    setPreviewUrl(null);
  };
  const AddModal = useCallback(() => (
    <Modal open={addModalOpen} onClose={() => setAddModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          Yangi reklama qo'shish
        </MDTypography>
        <Box component="form" onSubmit={handleAddSubmit(onAddSubmit)} sx={{ mt: 2 }}>

          <Controller
            name="image"
            control={addControl}
            defaultValue={null}
            rules={{ required: "Rasm tanlash shart" }}
            render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
              <Box sx={{ mt: 2 }}>
                <input
                  {...field}
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="image-upload"
                  onChange={(e) => handleImageChange(e, onChange)}
                />
                {!previewUrl ? (
                  <label htmlFor="image-upload">
                    <Box
                      sx={{
                        border: '2px dashed #3f51b5',
                        borderRadius: '8px',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'rgba(63, 81, 181, 0.04)',
                        },
                      }}
                    >
                      <Icon sx={{ fontSize: 48, color: '#3f51b5' }}>cloud_upload</Icon>
                      <MDTypography variant="body1" sx={{ mt: 1, fontSize: "18px" }}>
                        Rasm yuklash uchun bosing yoki bu yerga tashlang
                      </MDTypography>
                    </Box>
                  </label>
                ) : (
                  <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      height: '200px',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={previewUrl}
                      alt="Preview"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <Button
                      onClick={handleImageDelete}
                      sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        minWidth: 'auto',
                        p: '6px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        },
                      }}
                    >
                      <Icon>delete</Icon>
                    </Button>
                  </Box>
                )}
                {error && (
                  <MDTypography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
                    {error.message}
                  </MDTypography>
                )}
              </Box>
            )}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setAddModalOpen(false)}>Bekor qilish</Button>
            <Button type="submit" color="primary">Qo'shish</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  ), [addModalOpen, handleAddSubmit, addControl, onAddSubmit, previewUrl]);

  const Author = ({ name, phone }) => (
    <MDBox display="flex" flexDirection="column">
      <MDTypography variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption" color="secondary">
        {phone}
      </MDTypography>
    </MDBox>
  );

  const Job = ({ url }) => (
    <MDBox lineHeight={1} textAlign="left">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={url} alt=""
          style={{ width: "40px", height: "40px", objectFit: "cover" }} />
      </a>
    </MDBox>
  );
  return {
    AddButton: (
      <Button
        onClick={handleOpenAddModal}
        variant="contained"
        color="white"
        size="large"
        sx={{ borderRadius: "8px", float: "right", mt: 2 }}
      >
        Yangi reklama qo'shish
      </Button>
    ),
    AlertComponent: (
      <Snackbar open={alertInfo.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertInfo.severity} sx={{ width: '100%' }}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    ),
    columns: [
      { Header: "muallif", accessor: "client", width: "30%", align: "left" },
      { Header: "rasm", accessor: "address", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
    Pagination: (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={total}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    ),
    DeleteModal,
    AddModal,
    Menu: renderMenu(),
  };
}