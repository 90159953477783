import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import { Pagination, PaginationItem } from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { sendRequest } from "config/apiFunctions";
import { Autocomplete, InputAdornment } from "@mui/material";
import { styled } from '@mui/material/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const validationSchema = yup.object().shape({
  clientPhoneNumber: yup
    .string()
    .matches(/^[0-9]{9}$/, "Telefon raqam 9 ta raqamdan iborat bo'lishi kerak")
    .required("Telefon raqam kiritish majburiy"),
  // ... boshqa maydonlar uchun validatsiya qoidalari
});
export default function Data() {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [filterValue, setFilterValue] = useState("Barchasi");
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuItem, setMenuItem] = useState(null);

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);


  const { control: addControl, handleSubmit: handleAddSubmit, reset: resetAddForm, watch: watchAddForm } = useForm({
    defaultValues: {
      description: "",
      regionId: "",
      districtId: "",
      villageId: "",
      price: "",
      addressLine: "",
      clientPhoneNumber: ""
    },
    resolver: yupResolver(validationSchema)

  });

  const { control: editControl, handleSubmit: handleEditSubmit, reset: resetEditForm, watch: watchEditForm } = useForm(
    {
      resolver: yupResolver(validationSchema)

    }
  );

  const watchedRegionId = watchAddForm("regionId");
  const watchedEditRegionId = watchEditForm("regionId");

  const watchedDistrictId = watchAddForm("districtId");
  const watchedEditDistrictId = watchEditForm("districtId");
  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };
  const openMenu = (event, item) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuItem(null);
  };
  const fetchJobPosts = useCallback(async (pageNumber = 1, filter = "", postedBy = "") => {
    setLoading(true);
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    if (postedBy && postedBy == "Barchasi") {
      postedBy = "";
    }
    try {
      const res = await sendRequest({
        method: "get",
        url: "/job-post",
        token,
        params: {
          page: pageNumber,
          limit: itemsPerPage,
          filter: filter,
          postedBy: postedBy
        }
      });
      if (res.success) {
        console.log("API response:", res.data);  // Log the API response
        setTotal(res.data.total);
        setTotalPages(res.data.totalPages);
        setPage(res.data.page);

        const newRows = res.data.jobPosts.map((item) => ({
          client: <Author name={item?.postedBy?.fullName ?? "Mavjud emas"} phone={item?.postedBy?.phoneNumber ?? "Mavjud emas"} />,

          status: (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status == "ACCEPTED" ? "Tasdiqlangan" : item.status} color="success" variant="gradient" size="sm" />
            </MDBox>
          ),
          address: <Job description={(item.address?.region?.name ? item.address?.region?.name : "") + ", " + (item.address?.district?.name ? item.address?.district?.name + ", " : "") + (item.address?.addressLine ? item.address?.addressLine : "") ?? "Mavjud emas"} />,
          viewCount: <Job description={item.viewCount || "Mavjud emas"} />,
          time: <Job description={formatDate(item.createdAt) || "Mavjud emas"} />,

          action: (
            <div>
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
                onClick={(event) => openMenu(event, item)}
              >
                more_vert
              </Icon>
            </div>
          ),
          description: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.description ?? "Mavjud emas"}
            </MDTypography>
          )
        }));
        console.log("New rows:", newRows);
        setRows(newRows);
        setData(res.data.jobPosts);
      }
    } catch (error) {
      console.error("Error fetching job posts:", error);
      showAlert("Ma'lumotlarni yuklashda xatolik yuz berdi", 'error');
    } finally {
      setLoading(false);
    }
  }, [itemsPerPage, showAlert, formatDate, openMenu]);




  const fetchRegions = async () => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({ method: "get", url: "/general/search/regions", token });
      if (res.success) {
        setRegions(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    setPage(1);
    fetchJobPosts(1, event.target.value);
  };

  const handlePageChange = (event, value) => {
    console.log("Page changed to:", value);
    setPage(value);
    fetchJobPosts(value, filterValue);
  };

  const fetchDistricts = async (regionId) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({ method: "get", url: `/general/districts/${regionId}`, token });
      if (res.success) {
        setDistricts(res.data);
        // Agar birinchi marta district tanlansa, uning village'larini o'rnatamiz
        if (res.data.length > 0 && res.data[0].villages) {
          setVillages(res.data[0].villages);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }));
  useEffect(() => {
    fetchJobPosts();
    fetchRegions();
  }, []);

  useEffect(() => {
    if (watchedRegionId) {
      fetchDistricts(watchedRegionId);
    }
  }, [watchedRegionId]);

  useEffect(() => {
    if (watchedEditRegionId) {
      fetchDistricts(watchedEditRegionId);
    }
  }, [watchedEditRegionId]);

  useEffect(() => {
    if (watchedDistrictId) {
      const selectedDistrict = districts.find(d => d.id === watchedDistrictId);
      if (selectedDistrict && selectedDistrict.villages) {
        setVillages(selectedDistrict.villages);
      }
    }
  }, [watchedDistrictId, districts]);

  useEffect(() => {
    if (watchedEditDistrictId) {
      const selectedDistrict = districts.find(d => d.id === watchedEditDistrictId);
      if (selectedDistrict && selectedDistrict.villages) {
        setVillages(selectedDistrict.villages);
      }
    }
  }, [watchedEditDistrictId, districts]);

  const createRows = useCallback((data) => {
    return data.map((item) => ({
      client: <Author name={item?.postedBy?.fullName ?? "Mavjud emas"} phone={item?.postedBy?.phoneNumber ?? "Mavjud emas"} />,
      description: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.description ?? "Mavjud emas"}
        </MDTypography>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status == "ACCEPTED" ? "Tasdiqlangan" : item.status} color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      address: <Job description={(item.address?.region?.name ? item.address?.region?.name : "") + ", " + (item.address?.district?.name ? item.address?.district?.name + ", " : "") + (item.address?.addressLine ? item.address?.addressLine : "") ?? "Mavjud emas"} />,
      viewCount: <Job description={item.viewCount || "Mavjud emas"} />,
      time: <Job description={formatDate(item.createdAt) || "Mavjud emas"} />,
      action: (
        <div>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={(event) => openMenu(event, item)}
          >
            more_vert
          </Icon>
        </div>
      ),
    }));
  }, [formatDate, openMenu]);

  const onEditSubmit = async (formData) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;

    // Compare formData with selectedItem to find modified fields
    const modifiedData = {};
    Object.keys(formData).forEach(key => {
      let formValue = formData[key];
      let originalValue;

      switch (key) {
        case 'regionId':
          originalValue = selectedItem.address?.regionId;
          break;
        case 'districtId':
          originalValue = selectedItem.address?.districtId;
          break;
        case 'villageId':
          originalValue = selectedItem.address?.villageId;
          break;
        case 'addressLine':
          originalValue = selectedItem.address?.addressLine;
          break;
        case 'clientPhoneNumber':
          originalValue = selectedItem.postedBy?.phoneNumber || null;
          break;
        case 'price':
          // Convert both to string for comparison
          originalValue = selectedItem[key]?.toString() || '';
          if (formValue != null) {
            formValue = formValue.toString();
          }
          break;
        default:
          originalValue = selectedItem[key];
      }

      // Check if the value has changed
      if (formValue !== originalValue && (formValue !== '' || originalValue != null)) {
        modifiedData[key] = formValue;
      }
    });

    if (Object.keys(modifiedData).length === 0) {
      showAlert("No changes were made", 'info');
      setEditModalOpen(false);
      return;
    }

    console.log("Modified data:", modifiedData);  // Debug log

    try {
      const res = await sendRequest({
        method: "patch",
        url: `/job-post/${selectedItem.id}`,
        token,
        data: modifiedData
      });
      if (res.success) {
        setData(prevData => prevData.map(item => item.id === selectedItem.id ? res.data : item));
        setEditModalOpen(false);
        showAlert("Job post updated successfully", 'success');
      } else {
        console.error("Edit failed:", res.error);
        showAlert("Failed to update job post", 'error');
      }
    } catch (error) {
      console.error("Edit error:", error);
      showAlert("Failed to update job post", 'error');
    }
  };

  const onAddSubmit = async (formData) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "post",
        url: "/job-post",
        token,
        data: formData
      });
      if (res.success) {
        console.log("Add response:", res.data);
        const updatedData = [res.data, ...data];
        setData(updatedData);
        setRows(createRows(updatedData));
        setAddModalOpen(false);
        resetAddForm();
        showAlert("New job post added successfully", 'success');
      } else {
        console.error("Add failed:", res.error.data.message);
        showAlert(res?.error?.data?.message ? res.error.data.message : "Yangi ish postini qo'shib bo'lmadi", 'error');
      }
    } catch (error) {
      console.error("Add error:", error);
      showAlert("Yangi ish postini qo'shib bo'lmadi", 'error');
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertInfo({ ...alertInfo, open: false });
  };
  const handleOpenEditModal = useCallback(async (item) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "get",
        url: `/job-post/${item.id}`,
        token
      });
      if (res.success) {
        setSelectedItem(res.data);
        const formData = {
          description: res.data.description,
          regionId: res.data.address?.regionId,
          districtId: res.data.address?.districtId,
          villageId: res.data.address?.villageId,
          addressLine: res.data.address?.addressLine,
          price: res.data.price
        };

        // Only set clientPhoneNumber if postedBy exists
        if (res.data.postedBy) {
          formData.clientPhoneNumber = res.data.postedBy.phoneNumber;
        }

        resetEditForm(formData);
        setEditModalOpen(true);
      } else {
        console.error("Failed to fetch post data:", res.error);
        showAlert("Failed to fetch post data", 'error');
      }
    } catch (error) {
      console.error("Error fetching post data:", error);
      showAlert("Error fetching post data", 'error');
    }
    closeMenu();
  }, [closeMenu, resetEditForm, showAlert]);

  const handleOpenDeleteModal = useCallback((item) => {
    setSelectedItem(item);
    setDeleteModalOpen(true);
    closeMenu();
  }, [closeMenu]);

  useEffect(() => {
    console.log("Data:", data);
    if (data && data.length) {
      const filteredData = data.filter(item => {
        const title = (item.title ?? "").toLowerCase();
        const filter = (filterValue ?? "").toLowerCase();
        return title.includes(filter);
      });
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = filteredData.slice(startIndex, endIndex);
      setTotal(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [data, page, filterValue]);

  const renderMenu = useCallback(() => (
    <Menu
      anchorEl={menuAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menuAnchorEl)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => { handleOpenDeleteModal(menuItem); closeMenu(); }}>O'chirish</MenuItem>
      <MenuItem onClick={() => { handleOpenEditModal(menuItem); closeMenu(); }}>O'zgartirish</MenuItem>
    </Menu>
  ), [menuAnchorEl, menuItem, handleOpenDeleteModal, handleOpenEditModal]);

  const handleOpenAddModal = useCallback(() => {
    resetAddForm();
    setAddModalOpen(true);
  }, [resetAddForm]);

  const handleDelete = useCallback(async () => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "delete",
        url: `/job-post/${selectedItem.id}`,
        token
      });
      if (res.success) {
        // const updatedData = await fetchJobPosts(page, filterValue);
        const updatedData = data.filter(item => item.id !== selectedItem.id);

        setData(updatedData);
        setRows(createRows(updatedData));
        setDeleteModalOpen(false);
        showAlert("Ish E'loni muvaffaqiyatli o'chirildi", 'success');
      } else {
        console.error("Delete failed:", res.error);
        showAlert("Ish E'lonini o'chirishda xatolik yuz berdi", 'error');
      }
    } catch (error) {
      console.error("Delete error:", error);
      showAlert("Ish E'lonini o'chirishda xatolik yuz berdi", 'error');
    }
  }, [selectedItem, page, filterValue, fetchJobPosts, showAlert]);

  const DeleteModal = useCallback(() => (
    <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          O'chirishni tasdiqlang
        </MDTypography>
        <MDTypography sx={{ mt: 2 }}>
          Haqiqatan ham bu elementni o'chirib tashlamoqchimisiz?
        </MDTypography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setDeleteModalOpen(false)}>Bekor qilish</Button>
          <Button onClick={handleDelete} color="error">O'chirish</Button>
        </Box>
      </Box>
    </Modal>
  ), [deleteModalOpen, handleDelete]);

  const AddModal = useCallback(() => (
    <Modal open={addModalOpen} onClose={() => setAddModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          Yangi ish qo'shish
        </MDTypography>
        <Box component="form" onSubmit={handleAddSubmit(onAddSubmit)} sx={{ mt: 2 }}>
          <Controller
            name="regionId"
            control={addControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={regions}
                  getOptionLabel={(option) => option.name}
                  value={regions.find(region => region.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Viloyat"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />

          <Controller
            name="districtId"
            control={addControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={districts}
                  getOptionLabel={(option) => option.name}
                  value={districts.find(district => district.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                    if (newValue) {
                      setVillages(newValue.villages || []);
                    } else {
                      setVillages([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tuman"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />

          <Controller
            name="villageId"
            control={addControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={villages}
                  getOptionLabel={(option) => option.name}
                  value={villages.find(village => village.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Mahalla"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />

          <Controller
            name="addressLine"
            control={addControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Ko'cha"
              />
            )}
          />
          <Controller
            name="clientPhoneNumber"
            control={addControl}
            rules={{ required: "Telefon raqam kiritish majburiy" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Telefon raqam"
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+998</InputAdornment>,
                }}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '').slice(0, 9);
                  field.onChange(value);
                }}
              />
            )}
          />
          <Controller
            name="price"
            control={addControl}

            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Narxi"
                error={!!error}
                helperText={error?.message}
                onChange={(e) => {
                  // const value = e.target.value.replace(/\D/g, '');
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
          <Controller
            name="description"
            control={addControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Izoh"
                multiline
                rows={4}
              />
            )}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setAddModalOpen(false)}>Orqaga</Button>
            <Button type="submit" color="primary">Saqlash</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  ), [addModalOpen, handleAddSubmit, addControl, onAddSubmit, regions, districts, villages]);

  const EditModal = useCallback(() => (
    <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          Ish e'lonini o'zgaritrish
        </MDTypography>
        <Box component="form" onSubmit={handleEditSubmit(onEditSubmit)} sx={{ mt: 2 }}>


          <Controller
            name="regionId"
            control={editControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={regions}
                  getOptionLabel={(option) => option.name}
                  value={regions.find(region => region.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Viloyat"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />

          <Controller
            name="districtId"
            control={editControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={districts}
                  getOptionLabel={(option) => option.name}
                  value={districts.find(district => district.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tuman"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />

          <Controller
            name="villageId"
            control={editControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={villages}
                  getOptionLabel={(option) => option.name}
                  value={villages.find(village => village.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Mahalla"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />
          <Controller
            name="addressLine"
            control={editControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Ko'cha"
              />
            )}
          />
          <Controller
            name="clientPhoneNumber"
            control={editControl}
            rules={{ required: "Telefon raqam kiritish majburiy" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Telefon raqam"
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+998</InputAdornment>,
                }}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '').slice(0, 9);
                  field.onChange(value);
                }}
              />
            )}
          />
          <Controller
            name="price"
            control={editControl}
            // rules={{
            //   pattern: {
            //     value: /^[0-9]*$/,
            //     message: "Faqat raqam kiritish mumkin"
            //   }
            // }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Narxi"
                error={!!error}
                helperText={error?.message}
                onChange={(e) => {
                  // const value = e.target.value.replace(/\D/g, '');
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
          <Controller
            name="description"
            control={editControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Izoh"
                multiline
                rows={4}
              />
            )}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setEditModalOpen(false)}>Orqaga</Button>
            <Button type="submit" color="primary">Save</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  ), [editModalOpen, handleEditSubmit, editControl, onEditSubmit, regions, districts, villages]);

  const Author = ({ name, phone }) => (
    <MDBox display="flex" flexDirection="column">
      <MDTypography variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption" color="secondary">
        {phone}
      </MDTypography>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );
  return {
    FilterInput: (
      <FormControl margin="normal" sx={{ minHeight: "40px", minWidth: "200px" }}>
        <InputLabel>Yaratuvchi turi</InputLabel>
        <Select
          value={filterValue}
          onChange={(event) => {
            setFilterValue(event.target.value);
            fetchJobPosts(1, "", event.target.value); // Added postedBy parameter
          }}
          label="Foydalanuvchi turi"
          sx={{ height: "40px" }}
        >
          <MenuItem value="Barchasi">
            Barchasi
          </MenuItem>
          <MenuItem value="JOB_POSTER">Ishchi Bor</MenuItem>
          <MenuItem value="ADMIN">Admin</MenuItem>
        </Select>
      </FormControl>
    ),
    AddButton: (
      <Button
        onClick={handleOpenAddModal}
        variant="contained"
        color="white"
        size="large"
        sx={{ borderRadius: "8px", float: "right", mt: 2 }}
      >
        Yangi ish qo'shish
      </Button>
    ),
    AlertComponent: (
      <Snackbar open={alertInfo.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertInfo.severity} sx={{ width: '100%' }}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    ),
    columns: [
      { Header: "Operator", accessor: "client", width: "10%", align: "left" },
      { Header: "Address", accessor: "address", align: "center" },
      { Header: "Ko'rishlar Soni", accessor: "viewCount", align: "center" },
      { Header: "Qachon qo'yilgan", accessor: "time", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
      { Header: "Izoh", accessor: "description", width: "14%", align: "left" },
    ],
    rows: rows,
    Pagination: (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          renderItem={(item) => (
            <CustomPaginationItem
              {...item}
              sx={{
                fontWeight: item.selected ? 'bold' : 'normal',
              }}
            />
          )}
        />
      </Box>),
    DeleteModal,
    EditModal,
    AddModal,
    Menu: renderMenu(),
  };
}