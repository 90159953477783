import React, { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Box, TextField, Button, InputAdornment } from '@mui/material';
import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { sendRequest } from "config/apiFunctions";

function Basic() {
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace('+998', '');
    if (value.length <= 9 && /^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userData = {
        "phoneNumber": phoneNumber,
        "password": password,
        "role": "SUPER_USER"
      };
      const res = await sendRequest({ method: "post", url: "/auth/sign-in", data: userData });
      if (res.success) {
        const { data } = res
        const user = {
          token: data.token,
          refresh: data.role,
          role: data.role,
        }
        if (user.role === "SUPER_USER") {
          localStorage.setItem('user', JSON.stringify(user));
          window.location.href = '/bosh-sahifa';
        } else {
          localStorage.setItem('user', JSON.stringify(user));
          window.location.href = '/foydalanuvchilar';
        }
      } else {
        handleClick()
        setErrorMessage("Maydonlardan birida hatolik yuz berdi . Tekshirib qaytadan urinib ko'ring iltimos")
      }
    } catch (error) {
      handleClick()
      setErrorMessage("Maydonlardan birida hatolik yuz berdi . Tekshirib qaytadan urinib ko'ring iltimos")
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Kirish
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Box component="form" onSubmit={handleSignIn} sx={{ width: '100%', maxWidth: 400 }}>
            <Box mb={2}>
              <TextField
                type="text"
                label="Telefon Raqam"
                fullWidth
                value={phoneNumber}
                onChange={handlePhoneChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+998</InputAdornment>,
                }}
                inputProps={{
                  maxLength: 9,
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Box mt={4} mb={1}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ color: 'white' }}
              >
                Kirish
              </Button>
            </Box>
          </Box>
        </MDBox>
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errorMessage}
        action={action}
      />
    </BasicLayout>
  );
}

export default Basic;