import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { sendRequest } from "config/apiFunctions";
import { Autocomplete, InputAdornment, PaginationItem } from "@mui/material";
import "./style.css"
import styled from "@emotion/styled";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Data() {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const itemsPerPage = 10;
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });


  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuItem, setMenuItem] = useState(null);

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);



  const { control: addControl, handleSubmit: handleAddSubmit, reset: resetAddForm, watch: watchAddForm } = useForm({
    defaultValues: {
      fullName: "",
      phoneNumber: "",
      password: "",
      status: "INACTIVE",
      regionId: "",
      districtId: "",
      villageId: ""
    }
  });
  const { control: editControl, handleSubmit: handleEditSubmit, reset: resetEditForm, watch: watchEditForm } = useForm();

  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
  };

  const openMenu = (event, item) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }));
  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuItem(null);
  };
  const createRows = useCallback((data) => {
    return data.map((item) => ({
      client: <Author name={item.fullName ?? "-"} phone={item.phoneNumber ?? "-"} />,
      buyurtmalar: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.acceptedJobPosts?.length ?? "0"}
        </MDTypography>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status} color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      address: <Job description={(item.address?.region?.name ? item.address?.region?.name : "") + ", " + (item.address?.district?.name ? item.address?.district?.name + ", " : "") + (item.address?.addressLine ? item.address?.addressLine : "") ?? "Mavjud emas"} />,
      action: (
        <div>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={(event) => openMenu(event, item)}
          >
            more_vert
          </Icon>
        </div>
      ),
    }));
  }, [openMenu]);

  const fetchUsers = useCallback(async (pageNumber = 1, filter = "") => {
    setLoading(true);
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "get",
        url: "/user",
        token,
        params: {
          page: pageNumber,
          limit: itemsPerPage,
          filter: filter
        }
      });
      if (res.success) {
        console.log("API response:", res.data);
        setData(res.data.data);
        setTotal(res.data.total);
        let totalPages = Math.ceil(res.data.total / 10);
        setTotalPages(totalPages);
        setPage(res.data.page);

        const newRows = res.data.data.map((item) => ({
          client: <Author name={item.fullName ?? "-"} phone={item.phoneNumber ?? "-"} />,
          buyurtmalar: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {item.acceptedJobPosts?.length ?? "0"}
            </MDTypography>
          ),
          status: (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status} color="success" variant="gradient" size="sm" />
            </MDBox>
          ),
          address: <Job description={(item.address?.region?.name ? item.address?.region?.name : "") + ", " + (item.address?.district?.name ? item.address?.district?.name + ", " : "") + (item.address?.addressLine ? item.address?.addressLine : "") ?? "Mavjud emas"} />,
          action: (
            <div>
              <Icon
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                fontSize="small"
                onClick={(event) => openMenu(event, item)}
              >
                more_vert
              </Icon>
            </div>
          ),
        }));
        setRows(newRows);
        setData(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      showAlert("Ma'lumotlarni yuklashda xatolik yuz berdi", 'error');
    } finally {
      setLoading(false);
    }
  }, [itemsPerPage, showAlert, openMenu]);
  const fetchRegions = async () => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({ method: "get", url: "/general/search/regions", token });
      if (res.success) {
        setRegions(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDistricts = async (regionId) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({ method: "get", url: `/general/districts/${regionId}`, token });
      if (res.success) {
        setDistricts(res.data);
        // Agar birinchi marta district tanlansa, uning village'larini o'rnatamiz
        if (res.data.length > 0 && res.data[0].villages) {
          setVillages(res.data[0].villages);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchRegions();
  }, []);
  const watchedRegionId = watchAddForm("regionId");
  const watchedEditRegionId = watchEditForm("regionId");

  useEffect(() => {
    if (watchedRegionId) {
      fetchDistricts(watchedRegionId);
    }
  }, [watchedRegionId]);

  useEffect(() => {
    if (watchedEditRegionId) {
      fetchDistricts(watchedEditRegionId);
    }
  }, [watchedEditRegionId]);

  const fetchVillages = async (districtId) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({ method: "get", url: `/general/villages/${districtId}`, token });
      if (res.success) {
        setVillages(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const watchedDistrictId = watchAddForm("districtId");
  const watchedEditDistrictId = watchEditForm("districtId");

  useEffect(() => {
    if (watchedDistrictId) {
      const selectedDistrict = districts.find(d => d.id === watchedDistrictId);
      if (selectedDistrict && selectedDistrict.villages) {
        setVillages(selectedDistrict.villages);
      }
    }
  }, [watchedDistrictId, districts]);

  useEffect(() => {
    if (watchedEditDistrictId) {
      const selectedDistrict = districts.find(d => d.id === watchedEditDistrictId);
      if (selectedDistrict && selectedDistrict.villages) {
        setVillages(selectedDistrict.villages);
      }
    }
  }, [watchedEditDistrictId, districts]);


  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertInfo({ ...alertInfo, open: false });
  };



  const handleOpenEditModal = useCallback(async (item) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "get",
        url: `/user/${item.id}`,
        token
      });
      if (res.success) {
        setSelectedItem(res.data);
        const formData = {
          fullName: res.data.fullName,
          phoneNumber: res.data.phoneNumber,
          status: res.data.status,
          regionId: res.data.address?.regionId,
          districtId: res.data.address?.districtId,
          villageId: res.data.address?.villageId,
          addressLine: res.data.address?.addressLine
        };
        resetEditForm(formData);
        setEditModalOpen(true);
      } else {
        console.error("Failed to fetch user data:", res.error);
        showAlert("Failed to fetch user data", 'error');
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      showAlert("Error fetching user data", 'error');
    }
    closeMenu();
  }, [closeMenu, resetEditForm, showAlert]);

  const handleOpenDeleteModal = useCallback((item) => {
    setSelectedItem(item);
    setDeleteModalOpen(true);
    closeMenu();
  }, [closeMenu]);

  useEffect(() => {
    if (data.length) {
      const filteredData = data.filter(item => {
        const fullName = (item.fullName ?? "").toLowerCase();
        const addressId = (item.addressId ?? "").toLowerCase();
        const filter = (filterValue ?? "").toLowerCase();
        return fullName.includes(filter) || addressId.includes(filter);
      });

      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = filteredData.slice(startIndex, endIndex);

      const newRows = paginatedData.map((item) => ({
        client: <Author name={item.fullName ?? "-"} phone={item.phoneNumber ?? "-"} />,
        buyurtmalar: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.acceptedJobPosts?.length ?? "0"}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status} color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        address: <Job description={(item.address?.region?.name ? item.address?.region?.name : "") + ", " + (item.address?.district?.name ? item.address?.district?.name + ", " : "") + (item.address?.addressLine ? item.address?.addressLine : "") ?? "Mavjud emas"} />,
        action: (
          <div>
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
              onClick={(event) => openMenu(event, item)}
            >
              more_vert
            </Icon>
          </div>
        ),
      }));
      setTotal(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [data, page, filterValue]);

  const renderMenu = useCallback(() => (
    <Menu
      anchorEl={menuAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menuAnchorEl)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => { handleOpenDeleteModal(menuItem); closeMenu(); }}>O'chirish</MenuItem>
      <MenuItem onClick={() => { handleOpenEditModal(menuItem); closeMenu(); }}>O'zgartirish</MenuItem>
    </Menu>
  ), [menuAnchorEl, menuItem, handleOpenDeleteModal, handleOpenEditModal]);

  const handleOpenAddModal = useCallback(() => {
    resetAddForm();
    setAddModalOpen(true);
  }, [resetAddForm]);

  const handleDelete = useCallback(async () => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "delete",
        url: `/user/${selectedItem.id}`,
        token
      });
      if (res.success) {
        // const updatedData = await fetchUsers(page, filterValue);
        const updatedData = data.filter(item => item.id !== selectedItem.id);
        setData(updatedData);
        setRows(createRows(updatedData));
        setDeleteModalOpen(false);
        showAlert("Foydalanuvchi muvaffaqiyatli o'chirildi", 'success');
      } else {
        console.error("Delete failed:", res.error);
        showAlert("Foydalanuvchini o'chirishda xatolik yuz berdi", 'error');
      }
    } catch (error) {
      console.error("Delete error:", error);
      showAlert("Foydalanuvchini o'chirishda xatolik yuz berdi", 'error');
    }
  }, [selectedItem, page, filterValue, fetchUsers, createRows, showAlert]);

  const onEditSubmit = async (formData) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;

    // Compare formData with selectedItem to find modified fields
    const modifiedData = {};
    Object.keys(formData).forEach(key => {
      let formValue = formData[key];
      let originalValue;

      switch (key) {
        case 'regionId':
          originalValue = selectedItem.address?.regionId;
          break;
        case 'districtId':
          originalValue = selectedItem.address?.districtId;
          break;
        case 'villageId':
          originalValue = selectedItem.address?.villageId;
          break;
        case 'addressLine':
          originalValue = selectedItem.address?.addressLine;
          break;
        default:
          originalValue = selectedItem[key];
      }

      // Check if the value has changed
      if (formValue !== originalValue && formValue !== '') {
        modifiedData[key] = formValue;
      }
    });

    if (Object.keys(modifiedData).length === 0) {
      showAlert("No changes were made", 'info');
      setEditModalOpen(false);
      return;
    }

    try {
      const res = await sendRequest({
        method: "patch",
        url: `/user/${selectedItem.id}`,
        token,
        data: modifiedData
      });
      if (res.success) {
        console.log("Edit success:", res);
        // const updatedData = await fetchUsers(page, filterValue);
        const updatedData = data.map(item => {
          if (item.id === selectedItem.id) {
            return { ...item, ...modifiedData };
          }
          return item;
        }
        );
        setData(updatedData);
        setRows(createRows(updatedData));
        setEditModalOpen(false);
        showAlert("Foydalanuvchi ma'lumotlari muvaffaqiyatli yangilandi", 'success');
      } else {
        console.error("Edit failed:", res.error);
        showAlert("Foydalanuvchi ma'lumotlarini yangilashda xatolik yuz berdi", 'error');
      }
    } catch (error) {
      console.error("Edit error:", error);
      showAlert("Foydalanuvchi ma'lumotlarini yangilashda xatolik yuz berdi", 'error');
    }
  };

  const onAddSubmit = async (formData) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "post",
        url: "/user",
        token,
        data: formData
      });
      if (res.success) {
        // const updatedData = await fetchUsers(page, filterValue);
        const updatedData = [res.data, ...data];
        setData(updatedData);
        setRows(createRows(updatedData));
        setAddModalOpen(false);
        resetAddForm();
        showAlert("Yangi foydalanuvchi muvaffaqiyatli qo'shildi", 'success');
      } else {
        console.error("Add failed:", res.error);
        showAlert("Yangi foydalanuvchi qo'shishda xatolik yuz berdi", 'error');
      }
    } catch (error) {
      console.error("Add error:", error);
      showAlert("Yangi foydalanuvchi qo'shishda xatolik yuz berdi", 'error');
    }
  };
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchUsers(value, filterValue);
  };

  const DeleteModal = useCallback(() => (
    <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          O'chirishni tasdiqlang
        </MDTypography>
        <MDTypography sx={{ mt: 2 }}>
          Haqiqatan ham bu elementni o'chirib tashlamoqchimisiz?
        </MDTypography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setDeleteModalOpen(false)}>Bekor qilish</Button>
          <Button onClick={handleDelete} color="error">O'chirish</Button>
        </Box>
      </Box>
    </Modal>
  ), [deleteModalOpen, handleDelete]);

  const AddModal = useCallback(() => (
    <Modal open={addModalOpen} onClose={() => setAddModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          Yangi foydalanuvchi qo'shish
        </MDTypography>
        <Box component="form" onSubmit={handleAddSubmit(onAddSubmit)} sx={{ mt: 2 }}>
          <Controller
            name="fullName"
            control={addControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="To'liq ismi"
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={addControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Telefon raqam"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+998</InputAdornment>,
                }}
              />
            )}
          />
          <Controller
            name="password"
            control={addControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Parol"
                type="password"
              />
            )}
          />
          <Controller
            name="regionId"
            control={addControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={regions}
                  getOptionLabel={(option) => option.name}
                  value={regions.find(region => region.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Viloyat"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />

          <Controller
            name="districtId"
            control={addControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={districts}
                  getOptionLabel={(option) => option.name}
                  value={districts.find(district => district.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                    if (newValue && newValue.villages) {
                      setVillages(newValue.villages);
                    } else {
                      setVillages([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tuman"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />

          <Controller
            name="villageId"
            control={addControl}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={villages}
                getOptionLabel={(option) => option.name}
                value={villages.find(village => village.id === value) || null}
                onChange={(event, newValue) => {
                  onChange(newValue ? newValue.id : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Mahalla"
                    variant="outlined"
                  />
                )}
                style={{ height: 40, marginBottom: 2 }}
                className="mt_"
              />
            )}
          />


          <Controller
            name="status"
            control={addControl}
            render={({ field }) => (
              <FormControl fullWidth margin="normal" className="mt_">
                <InputLabel>Status</InputLabel>
                <Select {...field} style={{ height: 40 }}>
                  <MenuItem value="ACTIVE">Faol</MenuItem>
                  <MenuItem value="INACTIVE">Faol emas</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setAddModalOpen(false)}>Bekor qilish</Button>
            <Button type="submit" color="primary">Qo'shish</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  ), [addModalOpen, handleAddSubmit, addControl, onAddSubmit, regions, districts, villages]);

  const EditModal = useCallback(() => (
    <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          Ma'lumotlarni tahrirlash
        </MDTypography>
        <Box component="form" onSubmit={handleEditSubmit(onEditSubmit)} sx={{ mt: 2 }}>
          <Controller
            name="fullName"
            control={editControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="To'liq ismi"
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={editControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Telefon raqam"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+998</InputAdornment>,
                }}
              />
            )}
          />

          <Controller
            name="regionId"
            control={editControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={regions}
                  getOptionLabel={(option) => option.name}
                  value={regions.find(region => region.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Viloyat"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />
          <Controller
            name="districtId"
            control={editControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={districts}
                  getOptionLabel={(option) => option.name}
                  value={districts.find(district => district.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tuman"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />

          <Controller
            name="villageId"
            control={editControl}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={villages}
                  getOptionLabel={(option) => option.name}
                  value={villages.find(village => village.id === value) || null}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Mahalla"
                      variant="outlined"
                    />
                  )}
                  style={{ height: 40, marginBottom: 2 }}
                />
              </FormControl>
            )}
          />
          <Controller
            name="addressLine"
            control={editControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Manzil"
              />
            )}
          />
          <Controller
            name="status"
            control={editControl}
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel>Holati</InputLabel>
                <Select {...field} style={{ height: 40 }}>
                  <MenuItem value="ACTIVE">Faol</MenuItem>
                  <MenuItem value="INACTIVE">Faol emas</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setEditModalOpen(false)}>Bekor qilish</Button>
            <Button type="submit" color="primary">Saqlash</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  ), [editModalOpen, handleEditSubmit, editControl, onEditSubmit, regions, districts, villages]);

  const Author = ({ name, phone }) => (
    <MDBox display="flex" flexDirection="column">
      <MDTypography variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption" color="secondary">
        {phone}
      </MDTypography>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );


  return {
    FilterInput: (
      <TextField
        fullWidth
        margin="normal"
        label="Ism yoki manzil bo'yicha filtrlang"
        value={filterValue}
        onChange={handleFilterChange}
        sx={{ mb: 2 }}
      />
    ),
    AddButton: (
      <Button
        onClick={handleOpenAddModal}
        variant="contained"
        color="white"
        size="large"
        sx={{ borderRadius: "8px", float: "right", mt: 2 }}
      >
        Yangi foydalanuvchi qo'shish
      </Button>
    ),
    AlertComponent: (
      <Snackbar open={alertInfo.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertInfo.severity} sx={{ width: '100%' }}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    ),
    columns: [
      { Header: "Name/Phone", accessor: "client", width: "30%", align: "left" },
      { Header: "Buyurtmalar Soni", accessor: "buyurtmalar", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Address", accessor: "address", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
    Pagination: (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          renderItem={(item) => (
            <CustomPaginationItem
              {...item}
              sx={{
                fontWeight: item.selected ? 'bold' : 'normal',
              }}
            />
          )}
        />
      </Box>
    ),
    DeleteModal,
    EditModal,
    AddModal,
    Menu: renderMenu(),
  };
}