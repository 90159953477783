import axios from 'axios';

const MainURL = 'https://api.ishbor.andasoft.uz';
// const MainURL = 'https://jf.andasoft.uz';

const axiosInstance = axios.create({
  baseURL: MainURL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      if (!window.location.href.includes('authentication/sign-in')) {
        window.location.href = '/authentication/sign-in';
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export const sendRequest = async ({ method, url, data = {}, token = null, params = {} }) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  try {
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    return { success: true, data: response.data };
  } catch (error) {
    return {
      success: false,
      error: error.response ? { status: error.response.status, data: error.response.data } : { status: 500, data: 'Server error' },
    };
  }
};