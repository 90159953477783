import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Tables2 from "layouts/tables2";
import Tables3 from "layouts/tables3";
import Tables4 from "layouts/tables4";
import Tables5 from "layouts/tables5";
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Foydalanuvchilar",
    key: "tables",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/foydalanuvchilar",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Ish E'lonlari",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/ish-elonlari",
    component: <Tables3 />,
  },
];

try {
  const user = JSON.parse(localStorage.getItem("userData") || "{}");
  const userRole = user.role;
  if (userRole === "SUPER_USER") {
    routes.unshift({
      type: "collapse",
      name: "Bosh Sahifa",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/bosh-sahifa",
      component: <Dashboard />,
    });
    routes.push({
      type: "collapse",
      name: "Reklamalar",
      key: "reklamalar",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/reklamalar",
      component: <Tables4 />,
    });
    routes.push({
      type: "collapse",
      name: "Operatorlar",
      key: "operators",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/operators",
      component: <Tables2 />,
    });
    routes.push({
      type: "collapse",
      name: "Ma'lumotlar",
      key: "Ma'lumotlar",
      icon: <Icon fontSize="small">information</Icon>,
      route: "/malumotlar",
      component: <Tables5 />,
    });
  }
} catch (error) {
  console.error("Foydalanuvchi ma'lumotlarini olishda xatolik yuz berdi:", error);
}

export default routes;