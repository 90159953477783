
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables3/data/authorsTableData";
import { useMemo } from "react";

function Tables() {
  const { columns, rows: rawRows, loading, FilterInput, Pagination, DeleteModal, EditModal, AddModal, Menu, AddButton, AlertComponent } = authorsTableData();

  const rows = useMemo(() => rawRows || [], [rawRows]);
  console.log('====================================');
  console.log("rows", rows);
  console.log('====================================');

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="flex">
        {AddButton}
        {FilterInput}
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Ish E'lonlari Ro'yhati
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <MDBox textAlign="center" p={3}>
                    <MDTypography variant="h6" color="text">
                      Ma'lumotlar yuklanmoqda...
                    </MDTypography>
                  </MDBox>
                ) : !rows || rows.length === 0 ? (
                  <MDBox textAlign="center" p={3}>
                    <MDTypography variant="h6" color="text">
                      Ma'lumot mavjud emas
                    </MDTypography>
                  </MDBox>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    canSearch={false}
                    pagination={{ variant: "gradient", color: "info" }}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      {Pagination}
      {Menu}
      <DeleteModal />
      <AddModal />
      <EditModal />
      {AlertComponent}
    </DashboardLayout>

  );
}

export default Tables;
